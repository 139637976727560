<template>
  <div>
    <div class="container">
      <Base/>
    </div>
  </div>
</template>

<script>
import Base from './components/Base.vue'

export default {
  name: 'App',
  components: {
    Base
  }
}
</script>

